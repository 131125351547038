import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

const NumbersGroup = ({ numbers }) => {
  return (
    <Box>
      <Flex sx={{ flexWrap: "wrap" }}>
        {numbers.map(number => (
          <Number number={number} key={number.id} />
        ))}
      </Flex>
    </Box>
  )
}

const Number = ({ number }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Box mr={5} mb={4} ref={ref}>
      <Heading
        dir="invalid"
        variant="h1"
        sx={{ marginTop: 0, marginBottom: 2, color: "primary" }}
      >
        <CountUp
          end={number.float}
          prefix={number.prefix ? `${number.prefix}` : null}
          suffix={number.suffix ? `${number.suffix}` : null}
          start={startCount}
        />
      </Heading>
      <Text as="p">{number.legend}</Text>
    </Box>
  )
}

export default NumbersGroup
