import React, { useState } from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { ArrowUpRight } from "react-feather"
import linkSwitch from "../../utils/linkSwitch"
import { GatsbyImage } from "gatsby-plugin-image"

const FaqList = ({ items }) => {
  return (
    <Box mb={[3, 5]}>
      <AccordionBlock items={items} />
    </Box>
  )
}

const AccordionBlock = ({ items }) => {
  const [expandedId, setExpandedId] = useState([])
  function isExpanded(id) {
    return expandedId.includes(id)
  }

  return (
    <AccordionWrapper
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      onChange={uuid => setExpandedId(uuid)}
    >
      {items.map((item, index) => (
        <Box
          sx={{
            borderTop: "1px solid",
            borderColor: "lightGrey",
          }}
        >
          <AccordionItem key={item.id} uuid={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <Box
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "lightBackground",
                      span: { fontWeight: "700" },
                    },
                    backgroundColor: isExpanded(item.id)
                      ? "lightBackground"
                      : null,
                    span: { fontWeight: isExpanded(item.id) ? "bold" : null },
                  }}
                >
                  <Container>
                    <Flex>
                      <Box sx={{ minWidth: "32px" }}>
                        <Text dir="invalid" sx={{ fontSize: 3, color: "primary", mt: 0 }}>
                          {index + 1}
                        </Text>
                      </Box>
                      <Flex>
                        <Text dir="invalid" variant="h5" sx={{ m: 0 }}>
                          {item.question}
                        </Text>
                      </Flex>
                    </Flex>
                  </Container>
                </Box>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Container sx={{ paddingTop: [0, 0, 0] }}>
                <Box
                  sx={{ p: { fontSize: 3, lineHeight: 1.4 } }}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              </Container>
            </AccordionItemPanel>
          </AccordionItem>
        </Box>
      ))}
    </AccordionWrapper>
  )
}

export default FaqList
