import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { ArrowUpRight } from "react-feather"
import linkSwitch from "../../utils/linkSwitch"
import { GatsbyImage } from "gatsby-plugin-image"
import { i18nContext } from "../../context/i18nContext"

const Accordion = ({ title, subtitle, items, alternate, image, link }) => {
  return (
    <>
      {alternate ? (
        <Box mb={[0, 0, 7]} sx={{ position: "relative", overflow: "hidden" }}>
          {image && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                ".gatsby-image-wrapper": {
                  height: "100%",
                  display: "block",
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} />
            </Box>
          )}
          <Box
            sx={{
              position: "relative",
              paddingTop: [4, "20rem"],
              paddingBottom: [4, "20rem"],
            }}
          >
            <Grid columns={[1, 1, "2fr 3fr"]} gap={[0]}>
              <Box>
                <Box
                  sx={{
                    backgroundColor: "primary",
                    padding: [4, 5, 6],
                    height: "auto",
                    width: "80%",
                    marginLeft: "auto",
                    marginTop: "10%",
                  }}
                >
                  <Heading
                    as="h2"
                    dir="invalid"
                    variant="h2"
                    sx={{ color: "light", mt: 0 }}
                  >
                    {title}
                  </Heading>
                  <Text as="h4" dir="invalid" variant="h4" sx={{ fontSize: 3 }}>
                    {subtitle}
                  </Text>
                  {link && (
                    <Flex
                      sx={{
                        alignItems: "center",
                        mt: 4,
                        a: { color: "light" },
                        fontSize: 2,
                      }}
                    >
                      <Flex
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "full",
                          width: "50px",
                          height: "50px",
                          minWidth: "50px",
                          minHeight: "50px",
                          mr: 3,
                          backgroundColor: "light",
                        }}
                      >
                        <ArrowUpRight
                          color={getColor(themeUiTheme, "primary")}
                        />
                      </Flex>
                      {linkSwitch(link, link.locale)}
                    </Flex>
                  )}
                </Box>
              </Box>
              <Box sx={{ backgroundColor: "light" }}>
                <AccordionBlock items={items} />
              </Box>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box mb={[3, 5]}>
          <Container>
            <Grid columns={[1, 1, "2fr 3fr"]} gap={[32, 64, 128]}>
              <Box></Box>
              <Heading dir="invalid" variant="h2">
                {title}
              </Heading>
            </Grid>
          </Container>
          <AccordionBlock items={items} />
        </Box>
      )}
    </>
  )
}

const AccordionBlock = ({ items }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <AccordionWrapper allowMultipleExpanded={true} allowZeroExpanded={true}>
          {items.map((item, index) => (
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: "lightGrey",
              }}
            >
              <AccordionItem key={item.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <Box
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "lightBackground",
                        },
                      }}
                    >
                      <Container>
                        <Grid
                          columns={["1fr 5fr", "1fr 5fr", "2fr 3fr"]}
                          gap={[32, 64, 128]}
                          dir={t.locale === "he" ? "rtl" : "auto"}
                        >
                          <Box>
                            <Text
                              dir="invalid"
                              sx={{ fontSize: 7, color: "primary", mt: 0 }}
                            >
                              {index + 1}
                            </Text>
                          </Box>
                          <Flex>
                            <Text variant="h5">{item.title}</Text>
                          </Flex>
                        </Grid>
                      </Container>
                    </Box>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Container sx={{ paddingTop: [0, 0, 0] }}>
                    <Grid
                      columns={[1, 1, "2fr 3fr"]}
                      gap={[32, 64, 128]}
                      sx={{
                        borderTop: "1px solid",
                        borderColor: "lightGrey",
                        paddingTop: [2, 5],
                      }}
                      dir={t.locale === "he" ? "rtl" : "auto"}
                    >
                      <Text dir="invalid" as="h4" variant="h4" sx={{ mt: 0 }}>
                        {item.subtitle}
                      </Text>
                      <RichContentStructuredText text={item.body} />
                    </Grid>
                  </Container>
                </AccordionItemPanel>
              </AccordionItem>
            </Box>
          ))}
        </AccordionWrapper>
      )}
    </i18nContext.Consumer>
  )
}

export default Accordion
