import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Grid } from "@theme-ui/components"
import CountUp from "react-countup"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui/index"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import { ArrowUpRight } from "react-feather"
import linkSwitch from "../../utils/linkSwitch"

const NumbersCollection = ({
  title,
  image,
  numbers,
  link,
  addMarginBottom,
}) => {
  const backgroundColorArray = [
    "lightBackground",
    "darkBackground",
    "primary",
    "dark",
  ]
  const colorArray = ["secondary", "light", "light", "light"]
  return (
    <Box sx={{ position: "relative", mb: addMarginBottom ? [0, 0, 7] : 0 }}>
      <Box
        sx={{
          position: ["relative", "relative", "absolute"],
          backgroundColor: "lightBackground",
          width: ["100%", "100%", "50%"],
          height: ["auto", "100%", "80%"],
          top: [0, 0, "10%"],
          left: 0,
          mY: 5,
          ".gatsby-image-wrapper": {
            width: "100%",
            height: "100%",
            display: "block",
          },
        }}
      >
        {image && <GatsbyImage image={image.gatsbyImageData} alt="" />}
        <Box
          sx={{
            position: ["relative", "absolute"],
            top: 0,
            left: 0,
            paddingLeft: [4, 5, 6, 6],
            paddingRight: [4, 4, 5, 9],
            py: [4, 7],
            backgroundColor: ["dark", "transparent"],
          }}
        >
          {title && (
            <Text
              as="h2"
              variant="h2"
              sx={{
                color: "light",
                fontSize: [4, 5, 5, "40px"],
                hyphens: "auto",
              }}
            >
              {title}
            </Text>
          )}
          {link && (
            <Flex
              sx={{
                alignItems: "center",
                mt: 4,
                a: { color: "light" },
                fontSize: 2,
              }}
            >
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "full",
                  width: "50px",
                  height: "50px",
                  minWidth: "50px",
                  minHeight: "50px",
                  mr: 3,
                  backgroundColor: "primary",
                }}
              >
                <ArrowUpRight color={getColor(themeUiTheme, "light")} />
              </Flex>
              {linkSwitch(link, link.locale)}
            </Flex>
          )}
        </Box>
      </Box>
      <Grid columns={[1, 1, "2fr 3fr"]} gap={[0, 0, 128]}>
        <Box></Box>
        <Flex
          sx={{
            position: "relative",
            flexWrap: "wrap",
          }}
        >
          {numbers.map((number, index) => (
            <Box
              sx={{
                minWidth: ["100%", "50%"],
                maxWidth: ["100%", "50%"],
                minHeight: ["16rem", "30rem"],
                backgroundColor: backgroundColorArray[index],
                p: 4,
              }}
            >
              <Number number={number} color={colorArray[index]} />
            </Box>
          ))}
        </Flex>
      </Grid>
    </Box>
  )
}

const Number = ({ number, color }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Flex
      mr={4}
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
      ref={ref}
    >
      <Heading
        dir="invalid"
        variant="h1"
        sx={{ marginTop: 0, marginBottom: 2, color: color }}
      >
        <CountUp
          end={number.float}
          prefix={number.prefix ? `${number.prefix}` : null}
          suffix={number.suffix ? `${number.suffix}` : null}
          start={startCount}
        />
      </Heading>
      <Text as="p" variant="h6" color={color}>
        {number.legend}
      </Text>
    </Flex>
  )
}

export default NumbersCollection
