import React from "react"
import loadable from "@loadable/component"
import { Box, Container, Grid, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const BareLocationsMap = loadable(() => import("../blocks/bareLocationsMap"), {
  ssr: false,
})

const HeadquarterBlock = ({ body, locations, images }) => {
  return (
    <Box sx={{ position: "relative", mb: [0, 0, 7], backgroundColor: "dark" }}>
      <Box sx={{ backgroundColor: "", paddingY: [6, 6, 6, 7] }}>
        <Grid
          columns={[1, 1, "2fr 4fr 2fr", "2.75fr 3fr 2.5fr", "2fr 4.5fr 2fr"]}
          gap={0}
        >
          <Box
            dangerouslySetInnerHTML={{ __html: body }}
            sx={{
              backgroundColor: "primary",
              color: "white",
              px: 5,
              py: [5, 5, 0],
              boxShadow: [
                "none",
                "none",
                "0px 32px 0px #E30000, 64px 32px 0px #E30000, 0px -32px 0px #E30000, 64px -32px 0px #E30000",
              ],
              h3: {
                fontSize: 5,
                lineHeight: 1.3,
                fontWeight: "400",
              },
              h6: {
                textTransform: "uppercase",
                mt: 5,
                mb: 0,
              },
              a: {
                color: "white !important",
              },
            }}
          />
          <Box>
            <Flex sx={{ height: "100%" }}>
              <BareLocationsMap locations={locations} />
            </Flex>
          </Box>
          <Box sx={{ height: "100%" }}>
            <Grid sx={{ height: "100%" }} columns={[2, 2, 1]} gap="0">
              {images.map(image => (
                <Box
                  sx={{
                    ".gatsby-image-wrapper": {
                      height: "100%",
                      width: ["100%", "100%", "65%"],
                      display: "block",
                    },
                  }}
                >
                  <GatsbyImage image={image.gatsbyImageData} alt="" />
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default HeadquarterBlock
